const firebaseConfig = {
  projectId: "venice-house",
  appId: "1:737381140808:web:23ec42794dec8afe860b7d",
  databaseURL:
    "https://venice-house-default-rtdb.europe-west1.firebasedatabase.app",
  storageBucket: "venice-house.appspot.com",
  locationId: "us-central",
  apiKey: "AIzaSyCmg9gOuQRzbYwWC_X8likUC0jVY4PpZww",
  authDomain: "venice-house.firebaseapp.com",
  messagingSenderId: "737381140808",
  measurementId: "G-NGN2B12XNR",
};

export default firebaseConfig;
